export default {
  "message": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])};fn.source="hello i18n !!";return fn;})(),
  "Log in": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])};fn.source="Se connecter";return fn;})(),
  "Details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])};fn.source="Details";return fn;})(),
  "believe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nous croyons en l’égalité d’accès à l’information pour tous"])};fn.source="nous croyons en l’égalité d’accès à l’information pour tous";return fn;})(),
  "Free with AI": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratuit avec IA"])};fn.source="Gratuit avec IA";return fn;})(),
  "Home": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])};fn.source="Accueil";return fn;})(),
  "Talents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talents"])};fn.source="Talents";return fn;})(),
  "Pricing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])};fn.source="Prix";return fn;})(),
  "Blogs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogs"])};fn.source="Blogs";return fn;})(),
  "Contact": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])};fn.source="Contact";return fn;})(),
  "GO Platform All rights reserved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GO Plateforme Tous droits réservés"])};fn.source="GO Plateforme Tous droits réservés";return fn;})(),
  "Company": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])};fn.source="Entreprise";return fn;})(),
  "About us": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de nous"])};fn.source="À propos de nous";return fn;})(),
  "Contact us": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez nous"])};fn.source="Contactez nous";return fn;})(),
  "For candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les candidats"])};fn.source="Pour les candidats";return fn;})(),
  "Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplois"])};fn.source="Emplois";return fn;})(),
  "Services": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])};fn.source="Services";return fn;})(),
  "Welcome to your next growth opportunity.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue à votre prochaine opportunité de croissance."])};fn.source="Bienvenue à votre prochaine opportunité de croissance.";return fn;})(),
  "Get connected with full time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous avec des emplois à temps plein, freelance et à distance qui vous conviennent parfaitement et répondent à vos prérequis."])};fn.source="Connectez-vous avec des emplois à temps plein, freelance et à distance qui vous conviennent parfaitement et répondent à vos prérequis.";return fn;})(),
  "APPLY AS A TALENT": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POSTULEZ EN TANT QUE TALENT"])};fn.source="POSTULEZ EN TANT QUE TALENT";return fn;})(),
  "We open you up to a tool of possibilities.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous ouvrons à un outil de possibilités."])};fn.source="Nous vous ouvrons à un outil de possibilités.";return fn;})(),
  "Erase completely the stress": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éliminez complètement le stress et l'incertitude liés à la candidature directe auprès des entreprises, aux tableaux d'emploi et à la gestion de nombreux recruteurs. GO PLATFORM vous met en relation avec les opportunités que vous souhaitez et avec des entreprises partageant des intérêts similaires."])};fn.source="Éliminez complètement le stress et l'incertitude liés à la candidature directe auprès des entreprises, aux tableaux d'emploi et à la gestion de nombreux recruteurs. GO PLATFORM vous met en relation avec les opportunités que vous souhaitez et avec des entreprises partageant des intérêts similaires.";return fn;})(),
  "Pass Test": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer le test"])};fn.source="Passer le test";return fn;})(),
  "Pass our test": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passez notre test et montrez aux entreprises ce que vous savez faire. Les actions en disent plus que les mots."])};fn.source="Passez notre test et montrez aux entreprises ce que vous savez faire. Les actions en disent plus que les mots.";return fn;})(),
  "Join Community": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez la communauté"])};fn.source="Rejoignez la communauté";return fn;})(),
  "Improvement? No Problem!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration? Aucun problème! Nous vous aidons à apprendre de nouvelles compétences et à enrichir votre portfolio avec des projets secondaires non rémunérés."])};fn.source="Amélioration? Aucun problème! Nous vous aidons à apprendre de nouvelles compétences et à enrichir votre portfolio avec des projets secondaires non rémunérés.";return fn;})(),
  "Get Hired": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fais toi embauché"])};fn.source="Fais toi embauché";return fn;})(),
  "Are one off gigs your preference?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous préférez les missions ponctuelles? GO PLATFORM vous aide à partager votre profil avec des entreprises aux États-Unis, en Europe et dans la région du Golfe."])};fn.source="Vous préférez les missions ponctuelles? GO PLATFORM vous aide à partager votre profil avec des entreprises aux États-Unis, en Europe et dans la région du Golfe.";return fn;})(),
  "A One-Stop Platform": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une plateforme tout-en-un"])};fn.source="Une plateforme tout-en-un";return fn;})(),
  "You connect with GO PLATFORM": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous vous connectez à GO PLATFORM. Nous collaborons avec des dizaines d'entreprises, de plateformes et de centaines de recruteurs pour sélectionner la meilleure opportunité pour vous!"])};fn.source="Vous vous connectez à GO PLATFORM. Nous collaborons avec des dizaines d'entreprises, de plateformes et de centaines de recruteurs pour sélectionner la meilleure opportunité pour vous!";return fn;})(),
  "Get Started": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])};fn.source="Commencer";return fn;})(),
  "Talent Centric Process, Putting you FIRST!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processus centré sur le talent, en vous mettant en premier!"])};fn.source="Processus centré sur le talent, en vous mettant en premier!";return fn;})(),
  "GO PLATFORM collaborates with companies": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GO PLATFORM collabore avec des entreprises qui respectent le processus d'embauche standard international. Nous veillons à ce que des expériences de croissance de carrière bonnes et structurées soient adoptées pour vous."])};fn.source="GO PLATFORM collabore avec des entreprises qui respectent le processus d'embauche standard international. Nous veillons à ce que des expériences de croissance de carrière bonnes et structurées soient adoptées pour vous.";return fn;})(),
  "Trusted by Talents across AFRICA!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites confiance à des talents à travers l'AFRIQUE !"])};fn.source="Faites confiance à des talents à travers l'AFRIQUE !";return fn;})(),
  "“The consultants that reached out to me”": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Les consultants qui m'ont contacté au sujet du poste étaient super utiles, compréhensifs et incroyables. Ils étaient littéralement avec moi à chaque étape, de l'entretien à l'attente et enfin à l'obtention du poste. Merci Akorede et Oluwatobi.”"])};fn.source="“Les consultants qui m'ont contacté au sujet du poste étaient super utiles, compréhensifs et incroyables. Ils étaient littéralement avec moi à chaque étape, de l'entretien à l'attente et enfin à l'obtention du poste. Merci Akorede et Oluwatobi.”";return fn;})(),
  "Get matched to your dream opportunity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez la correspondance avec votre opportunité de rêve"])};fn.source="Trouvez la correspondance avec votre opportunité de rêve";return fn;})(),
  "Save time and Focus your energy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Économisez du temps et concentrez votre énergie pour vous rapprocher de votre prochaine meilleure opportunité. Rejoignez dès maintenant notre réseau de talents!"])};fn.source="Économisez du temps et concentrez votre énergie pour vous rapprocher de votre prochaine meilleure opportunité. Rejoignez dès maintenant notre réseau de talents!";return fn;})(),
  "Streamline your Recruitment. Start free.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisez votre recrutement. Commencez gratuitement."])};fn.source="Optimisez votre recrutement. Commencez gratuitement.";return fn;})(),
  "Choose the perfect plan for your business needs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le plan parfait pour les besoins de votre entreprise"])};fn.source="Choisissez le plan parfait pour les besoins de votre entreprise";return fn;})(),
  "Save 15%": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Économisez 15%"])};fn.source="Économisez 15%";return fn;})(),
  "on monthly plan!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur le plan mensuel!"])};fn.source="sur le plan mensuel!";return fn;})(),
  "Monthly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuel"])};fn.source="Mensuel";return fn;})(),
  "Yearly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuel"])};fn.source="Annuel";return fn;})(),
  "Free": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratuit"])};fn.source="Gratuit";return fn;})(),
  "Best for personal use": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idéal pour un usage personnel"])};fn.source="Idéal pour un usage personnel";return fn;})(),
  "/month": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/mois"])};fn.source="/mois";return fn;})(),
  "What you get:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce que vous obtenez:"])};fn.source="Ce que vous obtenez:";return fn;})(),
  "User": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])};fn.source="Utilisateur";return fn;})(),
  "5 Tests per Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 tests par évaluation"])};fn.source="5 tests par évaluation";return fn;})(),
  "Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])};fn.source="Évaluations";return fn;})(),
  "Reporting and analytics": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports et analyses"])};fn.source="Rapports et analyses";return fn;})(),
  "Pay per use ": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay per use "])};fn.source="Pay per use ";return fn;})(),
  "Best for Small Business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idéal pour les petites entreprises"])};fn.source="Idéal pour les petites entreprises";return fn;})(),
  "Upgraded features:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnalités améliorées:"])};fn.source="Fonctionnalités améliorées:";return fn;})(),
  "Users": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])};fn.source="Utilisateurs";return fn;})(),
  "Customer Support": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support client"])};fn.source="Support client";return fn;})(),
  "Most Popular": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le plus populaire"])};fn.source="Le plus populaire";return fn;})(),
  "Pro": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])};fn.source="Pro";return fn;})(),
  "Best for Staffing Companies": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idéal pour les entreprises de recrutement"])};fn.source="Idéal pour les entreprises de recrutement";return fn;})(),
  "Evolved Features:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnalités évoluées:"])};fn.source="Fonctionnalités évoluées:";return fn;})(),
  "Talent Marketplace": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place de marché des talents"])};fn.source="Place de marché des talents";return fn;})(),
  "Calender Integration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration du calendrier"])};fn.source="Intégration du calendrier";return fn;})(),
  "Subscription": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])};fn.source="Subscription";return fn;})(),
  "Custom": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisé"])};fn.source="Personnalisé";return fn;})(),
  "Unlimited features:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnalités illimitées:"])};fn.source="Fonctionnalités illimitées:";return fn;})(),
  "Unlimited Users": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs illimités"])};fn.source="Utilisateurs illimités";return fn;})(),
  "Unlimited Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations illimitées"])};fn.source="Évaluations illimitées";return fn;})(),
  "Automation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisation"])};fn.source="Automatisation";return fn;})(),
  "Learn-more": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])};fn.source="En savoir plus";return fn;})(),
  "Feature Table": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau des fonctionnalités"])};fn.source="Tableau des fonctionnalités";return fn;})(),
  "Unlimited": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illimité"])};fn.source="Illimité";return fn;})(),
  "Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation"])};fn.source="Évaluation";return fn;})(),
  "Tests Per Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests par évaluation"])};fn.source="Tests par évaluation";return fn;})(),
  "Assessment Library": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque d'évaluations"])};fn.source="Bibliothèque d'évaluations";return fn;})(),
  "Coding Languages": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langages de programmation"])};fn.source="Langages de programmation";return fn;})(),
  "Limited": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limité"])};fn.source="Limité";return fn;})(),
  "Flow": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flux"])};fn.source="Flux";return fn;})(),
  "Adds on": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouts"])};fn.source="Ajouts";return fn;})(),
  "Calendar Integration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration du calendrier"])};fn.source="Intégration du calendrier";return fn;})(),
  "Assessment templates based on job roles": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles d'évaluation basés sur les rôles professionnels"])};fn.source="Modèles d'évaluation basés sur les rôles professionnels";return fn;})(),
  "Corporate Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations d'entreprise"])};fn.source="Évaluations d'entreprise";return fn;})(),
  "Anti-Cheat Measures": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesures anti-triche"])};fn.source="Mesures anti-triche";return fn;})(),
  "Time Limits on Tests": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites de temps sur les tests"])};fn.source="Limites de temps sur les tests";return fn;})(),
  "IP Address Logging": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement des adresses IP"])};fn.source="Enregistrement des adresses IP";return fn;})(),
  "Copy-Paste Disabled": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier-coller désactivé"])};fn.source="Copier-coller désactivé";return fn;})(),
  "Webcam Snapshots": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instantanés de la webcam"])};fn.source="Instantanés de la webcam";return fn;})(),
  "Quality Control": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle de la qualité"])};fn.source="Contrôle de la qualité";return fn;})(),
  "GDPR Compliance": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformité GDPR"])};fn.source="Conformité GDPR";return fn;})(),
  "15 step": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processus en 15 étapes incluant des examens par les pairs, des tests de modèle, des rédacteurs, une révision par un psychométricien, et plus encore"])};fn.source="Processus en 15 étapes incluant des examens par les pairs, des tests de modèle, des rédacteurs, une révision par un psychométricien, et plus encore";return fn;})(),
  "To maintain test integrity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour maintenir l'intégrité des tests, GO PLATFORM dispose d'un algorithme exclusif de sélection d'éléments comprenant des contrôles d'exposition pour éviter qu'une question spécifique ne soit exposée à plus de 25% des candidats."])};fn.source="Pour maintenir l'intégrité des tests, GO PLATFORM dispose d'un algorithme exclusif de sélection d'éléments comprenant des contrôles d'exposition pour éviter qu'une question spécifique ne soit exposée à plus de 25% des candidats.";return fn;})(),
  "Dashboard": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])};fn.source="Tableau de bord";return fn;})(),
  "Library": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque"])};fn.source="Bibliothèque";return fn;})(),
  "Activity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])};fn.source="Activité";return fn;})(),
  "Manage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer"])};fn.source="Gérer";return fn;})(),
  "Talent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent"])};fn.source="Talent";return fn;})(),
  "Payouts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])};fn.source="Paiements";return fn;})(),
  "Schedule": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme"])};fn.source="Programme";return fn;})(),
  "Soon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt"])};fn.source="Bientôt";return fn;})(),
  "New Project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Projet"])};fn.source="Nouveau Projet";return fn;})(),
  "You currently have no Project, you can create one above.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez actuellement aucun projet. Vous pouvez en créer un ci-dessus."])};fn.source="Vous n'avez actuellement aucun projet. Vous pouvez en créer un ci-dessus.";return fn;})(),
  "Archives": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archives"])};fn.source="Archives";return fn;})(),
  "New Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle Évaluation"])};fn.source="Nouvelle Évaluation";return fn;})(),
  "Confirm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])};fn.source="Confirmer";return fn;})(),
  "Clear All": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout Effacer"])};fn.source="Tout Effacer";return fn;})(),
  "Free trial": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essai gratuit"])};fn.source="Essai gratuit";return fn;})(),
  "Refer a company": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référer une entreprise"])};fn.source="Référer une entreprise";return fn;})(),
  "Discounts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit gratuit"])};fn.source="Crédit gratuit";return fn;})(),
  "Get Now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenir maintenant"])};fn.source="Obtenir maintenant";return fn;})(),
  "no code": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un code"])};fn.source="Veuillez entrer un code";return fn;})(),
  "Terms": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes"])};fn.source="Termes";return fn;})(),
  "Privacy policy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politiques de confidentialité"])};fn.source="Politiques de confidentialité";return fn;})(),
  "hero-text": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recrutement basé sur <span class='grad'>les compétences<img loading='lazy'  src='/arch.svg'  class='arch' /></span>"])};fn.source="Recrutement basé sur <span class='grad'>les compétences<img loading='lazy'  src='/arch.svg'  class='arch' /></span>";return fn;})(),
  "hero-subtext": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Validez les compétences techniques, comportementales  <br />et les aspects psychométriques des candidats."])};fn.source=" Validez les compétences techniques, comportementales  <br />et les aspects psychométriques des candidats.";return fn;})(),
  "Start for free": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer gratuitement"])};fn.source="Commencer gratuitement";return fn;})(),
  "Inspired By": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspiré par"])};fn.source="Inspiré par";return fn;})(),
  "All the tools that you need": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des évaluations personnalisables pour révéler l'employabilité au-delà des CV."])};fn.source="Des évaluations personnalisables pour révéler l'employabilité au-delà des CV.";return fn;})(),
  "Accuracy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])};fn.source="Précision";return fn;})(),
  "Stop the Bleed from Unfilled Positions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stoppez les pertes financières des postes non pourvus"])};fn.source="Stoppez les pertes financières des postes non pourvus";return fn;})(),
  "Precise & Valid Skills Data": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données de compétences précises et valides"])};fn.source="Données de compétences précises et valides";return fn;})(),
  "Time Effectiveness": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficacité du temps"])};fn.source="Efficacité du temps";return fn;})(),
  "Accelerated Hiring Velocity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accélération de la vitesse d'embauche"])};fn.source="Accélération de la vitesse d'embauche";return fn;})(),
  "COMMUNITY": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMUNAUTÉ"])};fn.source="COMMUNAUTÉ";return fn;})(),
  "help-text": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pret à rejoindre la communauté GO PLATFORM"])};fn.source="Pret à rejoindre la communauté GO PLATFORM";return fn;})(),
  "community-text": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plus grande communauté de recruteurs et d'équipes d'IA conversationnelle axée sur le partage de projets, de références, de meilleures pratiques et la création des meilleurs assistants dans tous les secteurs."])};fn.source="La plus grande communauté de recruteurs et d'équipes d'IA conversationnelle axée sur le partage de projets, de références, de meilleures pratiques et la création des meilleurs assistants dans tous les secteurs.";return fn;})(),
  "Join Slack": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez Slack"])};fn.source="Rejoignez Slack";return fn;})(),
  "type to activate project": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["tapez <span> \"", _interpolate(_named("projectName")), " \"</span> pour ", _interpolate(_named("action")), " ce projet"])};fn.source="tapez <span> \"{projectName} \"</span> pour {action} ce projet";return fn;})(),
  "Edit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])};fn.source="Modifier";return fn;})(),
  "Project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet"])};fn.source="Projet";return fn;})(),
  "Duplicate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer"])};fn.source="Dupliquer";return fn;})(),
  "Archive": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver"])};fn.source="Archiver";return fn;})(),
  "Activate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])};fn.source="Activer";return fn;})(),
  "Delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])};fn.source="Supprimer";return fn;})(),
  "Cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])};fn.source="Annuler";return fn;})(),
  "type to delete project": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["tapez <span> \" ", _interpolate(_named("projectName")), " \"</span> pour supprimer ce projet"])};fn.source="tapez <span> \" {projectName} \"</span> pour supprimer ce projet";return fn;})(),
  "invite": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter"])};fn.source="Inviter";return fn;})(),
  "Boards": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])};fn.source="Dashboard";return fn;})(),
  "Role": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poste"])};fn.source="Poste";return fn;})(),
  "Experience": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expérience"])};fn.source="Expérience";return fn;})(),
  "Active": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])};fn.source="Activé";return fn;})(),
  "Archived": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivé"])};fn.source="Archivé";return fn;})(),
  "Time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps"])};fn.source="Temps";return fn;})(),
  "Status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])};fn.source="Statut";return fn;})(),
  "Candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidats"])};fn.source="Candidats";return fn;})(),
  "Hide": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher"])};fn.source="Cacher";return fn;})(),
  "Click here": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appuie ici"])};fn.source="Appuie ici";return fn;})(),
  "To Compare Plans": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour Comparer les Plans"])};fn.source="Pour Comparer les Plans";return fn;})(),
  "Log in to your account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à votre compte"])};fn.source="Connectez-vous à votre compte";return fn;})(),
  "Already have an account?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte?"])};fn.source="Vous avez déjà un compte?";return fn;})(),
  "Sign Up": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])};fn.source="S'inscrire";return fn;})(),
  "Email Address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])};fn.source="Adresse e-mail";return fn;})(),
  "example@example.com": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exemple&#64;exemple.com"])};fn.source="exemple&#64;exemple.com";return fn;})(),
  "Welcome to go platform!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur go platform!"])};fn.source="Bienvenue sur go platform!";return fn;})(),
  "Thank you for choosing our app. We hope you enjoy using it.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir choisi notre application. Nous espérons que vous prendrez plaisir à l'utiliser."])};fn.source="Merci d'avoir choisi notre application. Nous espérons que vous prendrez plaisir à l'utiliser.";return fn;})(),
  "No Thanks.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non merci."])};fn.source="Non merci.";return fn;})(),
  "Start Tour": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer la visite"])};fn.source="Commencer la visite";return fn;})(),
  "Online Users": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs en ligne"])};fn.source="Utilisateurs en ligne";return fn;})(),
  "Upgrade to PRO to get access to all features!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passez à la version PRO pour accéder à toutes les fonctionnalités!"])};fn.source="Passez à la version PRO pour accéder à toutes les fonctionnalités!";return fn;})(),
  "Total Spent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenses totales"])};fn.source="Dépenses totales";return fn;})(),
  "Learn More!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus!"])};fn.source="En savoir plus!";return fn;})(),
  "Add now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])};fn.source="Ajouter";return fn;})(),
  "Search Title or Keyword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par titre ou mot-clé"])};fn.source="Rechercher par titre ou mot-clé";return fn;})(),
  "Job title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre du poste"])};fn.source="Titre du poste";return fn;})(),
  "Seniority": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancienneté"])};fn.source="Ancienneté";return fn;})(),
  "Minimum Score": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score minimum"])};fn.source="Score minimum";return fn;})(),
  "Select job seniority": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le niveau de séniorité du poste"])};fn.source="Sélectionnez le niveau de séniorité du poste";return fn;})(),
  "Job Information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le poste"])};fn.source="Informations sur le poste";return fn;})(),
  "Select skills": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les compétences"])};fn.source="Sélectionnez les compétences";return fn;})(),
  "Don't show Salary?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas afficher le salaire?"])};fn.source="Ne pas afficher le salaire?";return fn;})(),
  "If you prefer, the job opening salary can also be kept private.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous préférez, le salaire de l'offre d'emploi peut également rester confidentiel."])};fn.source="Si vous préférez, le salaire de l'offre d'emploi peut également rester confidentiel.";return fn;})(),
  "Job Description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du poste"])};fn.source="Description du poste";return fn;})(),
  "Apply now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler maintenant"])};fn.source="Postuler maintenant";return fn;})(),
  "Total Tasks Done": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des tâches effectuées"])};fn.source="Total des tâches effectuées";return fn;})(),
  "Tasks Completed in last 7 Days": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâches terminées au cours des 7 derniers jours"])};fn.source="Tâches terminées au cours des 7 derniers jours";return fn;})(),
  "Validate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])};fn.source="Valider";return fn;})(),
  "Test Composition": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composition du test"])};fn.source="Composition du test";return fn;})(),
  "30 multiple choice questions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 questions à choix multiples"])};fn.source="30 questions à choix multiples";return fn;})(),
  "Tests included in the Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests inclus dans l'évaluation"])};fn.source="Tests inclus dans l'évaluation";return fn;})(),
  "Recent Candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidats récents"])};fn.source="Candidats récents";return fn;})(),
  "Invite": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter"])};fn.source="Inviter";return fn;})(),
  "The Task title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le titre de la tâche"])};fn.source="Le titre de la tâche";return fn;})(),
  "Reviewers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relecteurs"])};fn.source="Relecteurs";return fn;})(),
  "Click to add reviewers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour ajouter des relecteurs"])};fn.source="Cliquez pour ajouter des relecteurs";return fn;})(),
  "Add": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])};fn.source="Ajouter";return fn;})(),
  "Labels": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes"])};fn.source="Étiquettes";return fn;})(),
  "Click to add labels": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour ajouter des étiquettes"])};fn.source="Cliquez pour ajouter des étiquettes";return fn;})(),
  "Descriptions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriptions"])};fn.source="Descriptions";return fn;})(),
  "Add a description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une description"])};fn.source="Ajouter une description";return fn;})(),
  "Attachment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce jointe"])};fn.source="Pièce jointe";return fn;})(),
  "Remove": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])};fn.source="Supprimer";return fn;})(),
  "Uploaded at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargé à"])};fn.source="Téléchargé à";return fn;})(),
  "Click to attach a file": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour joindre un fichier"])};fn.source="Cliquez pour joindre un fichier";return fn;})(),
  "Comments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])};fn.source="Commentaires";return fn;})(),
  "Save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])};fn.source="Enregistrer";return fn;})(),
  "Add a comment...": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un commentaire..."])};fn.source="Ajouter un commentaire...";return fn;})(),
  "Add to cart": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])};fn.source="Ajouter au panier";return fn;})(),
  "Members": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])};fn.source="Membres";return fn;})(),
  "Checklist": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de contrôle"])};fn.source="Liste de contrôle";return fn;})(),
  "Actions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])};fn.source="Actions";return fn;})(),
  "Copy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])};fn.source="Copier";return fn;})(),
  "Move": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer"])};fn.source="Déplacer";return fn;})(),
  "No notifications": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune notification"])};fn.source="Aucune notification";return fn;})(),
  "Control your Payments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôlez vos paiements"])};fn.source="Contrôlez vos paiements";return fn;})(),
  "Filters": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])};fn.source="Filtres";return fn;})(),
  "Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])};fn.source="Nom";return fn;})(),
  "Action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])};fn.source="Action";return fn;})(),
  "at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])};fn.source="à";return fn;})(),
  "What role would you like to Hire?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel rôle souhaitez-vous embaucher ?"])};fn.source="Quel rôle souhaitez-vous embaucher ?";return fn;})(),
  "Developers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développeurs"])};fn.source="Développeurs";return fn;})(),
  "Software Developers, Data Scientists, DevOps, and QA": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développeurs logiciels, Data Scientists, DevOps et QA"])};fn.source="Développeurs logiciels, Data Scientists, DevOps et QA";return fn;})(),
  "Designers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designers"])};fn.source="Designers";return fn;})(),
  "Web, Mobile, UI/UX, Branding, and Visual Designers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designers web, mobile, UI/UX, branding et graphiques"])};fn.source="Designers web, mobile, UI/UX, branding et graphiques";return fn;})(),
  "Project Managers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chefs de projet"])};fn.source="Chefs de projet";return fn;})(),
  "Digital Project Managers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chefs de projet numériques, chefs de projet informatiques, Scrum Masters et coachs agiles"])};fn.source="Chefs de projet numériques, chefs de projet informatiques, Scrum Masters et coachs agiles";return fn;})(),
  "Product Managers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chefs de produit"])};fn.source="Chefs de produit";return fn;})(),
  "Digital Product Managers, Product Owners, and Business Analysts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chefs de produit numériques, propriétaires de produits et analystes métier"])};fn.source="Chefs de produit numériques, propriétaires de produits et analystes métier";return fn;})(),
  "Financial Experts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experts financiers"])};fn.source="Experts financiers";return fn;})(),
  "Financial Modelers, Fundraising Advisors, M&A and FP&A Experts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modélisateurs financiers, conseillers en levée de fonds, experts en fusions-acquisitions et en planification et analyse financières"])};fn.source="Modélisateurs financiers, conseillers en levée de fonds, experts en fusions-acquisitions et en planification et analyse financières";return fn;})(),
  "What type of project are you hiring for?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel type de projet embauchez-vous?"])};fn.source="Quel type de projet embauchez-vous?";return fn;})(),
  "New idea or project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle idée ou projet"])};fn.source="Nouvelle idée ou projet";return fn;})(),
  "Existing project that needs more resources": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet existant nécessitant plus de ressources"])};fn.source="Projet existant nécessitant plus de ressources";return fn;})(),
  "Ongoing assistance or consultation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance continue ou consultation"])};fn.source="Assistance continue ou consultation";return fn;})(),
  "None of the above, I'm just looking to learn more about RECRUITABLE.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune des options ci-dessus, je veux simplement en savoir plus sur RECRUITABLE."])};fn.source="Aucune des options ci-dessus, je veux simplement en savoir plus sur RECRUITABLE.";return fn;})(),
  "How long do you need the": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendant combien de temps avez-vous besoin du"])};fn.source="Pendant combien de temps avez-vous besoin du";return fn;})(),
  "for": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour"])};fn.source="pour";return fn;})(),
  "Less than 1 week": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins d'une semaine"])};fn.source="Moins d'une semaine";return fn;})(),
  "1 to 4 weeks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 à 4 semaines"])};fn.source="1 à 4 semaines";return fn;})(),
  "1 to 3 months": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 à 3 mois"])};fn.source="1 à 3 mois";return fn;})(),
  "3 to 6 months": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 à 6 mois"])};fn.source="3 à 6 mois";return fn;})(),
  "Longer than 6 months": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 6 mois"])};fn.source="Plus de 6 mois";return fn;})(),
  "I'll decide later": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je déciderai plus tard"])};fn.source="Je déciderai plus tard";return fn;})(),
  "How many": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien de"])};fn.source="Combien de";return fn;})(),
  "do you need": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avez-vous besoin"])};fn.source="avez-vous besoin";return fn;})(),
  "One": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un"])};fn.source="Un";return fn;})(),
  "Multiple": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs"])};fn.source="Plusieurs";return fn;})(),
  "A cross-functional team (developers, designers, project managers)": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une équipe pluridisciplinaire (développeurs, designers, chefs de projet)"])};fn.source="Une équipe pluridisciplinaire (développeurs, designers, chefs de projet)";return fn;})(),
  "What level of time commitment will you require from the": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel niveau d'engagement temporel allez-vous exiger du"])};fn.source="Quel niveau d'engagement temporel allez-vous exiger du";return fn;})(),
  "Fulltime (40 or more hrs/week)": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps plein (40 heures ou plus par semaine)"])};fn.source="Temps plein (40 heures ou plus par semaine)";return fn;})(),
  "Part time (Less than 40 hrs/week)": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps partiel (moins de 40 heures par semaine)"])};fn.source="Temps partiel (moins de 40 heures par semaine)";return fn;})(),
  "Hourly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l'heure"])};fn.source="À l'heure";return fn;})(),
  "What skills would you like to see in your new Hire?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelles compétences aimeriez-vous voir chez votre nouvelle embauche?"])};fn.source="Quelles compétences aimeriez-vous voir chez votre nouvelle embauche?";return fn;})(),
  "Popular skills for": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compétences populaires pour"])};fn.source="Compétences populaires pour";return fn;})(),
  "How many people are employed at your company?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien de personnes travaillent dans votre entreprise?"])};fn.source="Combien de personnes travaillent dans votre entreprise?";return fn;})(),
  "Less than 10": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins de 10"])};fn.source="Moins de 10";return fn;})(),
  "More than 1000": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 1000"])};fn.source="Plus de 1000";return fn;})(),
  "When do you need the": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand avez-vous besoin du"])};fn.source="Quand avez-vous besoin du";return fn;})(),
  "to start?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour commencer?"])};fn.source="pour commencer?";return fn;})(),
  "Immediately": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immédiatement"])};fn.source="Immédiatement";return fn;})(),
  "In 1 to 2 weeks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans 1 à 2 semaines"])};fn.source="Dans 1 à 2 semaines";return fn;})(),
  "More than 2 weeks from now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 2 semaines à partir de maintenant"])};fn.source="Plus de 2 semaines à partir de maintenant";return fn;})(),
  "Are you open to working with a remote": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous ouvert à travailler avec un"])};fn.source="Êtes-vous ouvert à travailler avec un";return fn;})(),
  "Yes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])};fn.source="Oui";return fn;})(),
  "No": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])};fn.source="Non";return fn;})(),
  "I'm not sure yet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne suis pas sûr encore"])};fn.source="Je ne suis pas sûr encore";return fn;})(),
  "Success! Let's connect you with GO platform": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès! Connectons-vous à la plateforme GO"])};fn.source="Succès! Connectons-vous à la plateforme GO";return fn;})(),
  "Company's name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])};fn.source="Nom de l'entreprise";return fn;})(),
  "Contact's name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du contact"])};fn.source="Nom du contact";return fn;})(),
  "Back": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])};fn.source="Retour";return fn;})(),
  "Continue": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])};fn.source="Continuer";return fn;})(),
  "to start": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour commencer"])};fn.source="pour commencer";return fn;})(),
  "Featured Companies": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises en vedette"])};fn.source="Entreprises en vedette";return fn;})(),
  "Settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])};fn.source="Paramètres";return fn;})(),
  "Profile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])};fn.source="Profil";return fn;})(),
  "Spent this month": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépensé ce mois-ci"])};fn.source="Dépensé ce mois-ci";return fn;})(),
  "Any questions?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des questions ?"])};fn.source="Des questions ?";return fn;})(),
  "Write or call us. We will write back within 12h": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous. Nous répondons dans les 12 heures"])};fn.source="Contactez-nous. Nous répondons dans les 12 heures";return fn;})(),
  "Report a bug": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un bug"])};fn.source="Signaler un bug";return fn;})(),
  "Others": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])};fn.source="Autres";return fn;})(),
  "Feedback": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])};fn.source="Retour";return fn;})(),
  "Request": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande"])};fn.source="Demande";return fn;})(),
  "Partnership": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenariat"])};fn.source="Partenariat";return fn;})(),
  "Urgency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgence"])};fn.source="Urgence";return fn;})(),
  "Send": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])};fn.source="Envoyer";return fn;})(),
  "Individual Score": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Individuel"])};fn.source="Score Individuel";return fn;})(),
  "Pending": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])};fn.source="En attente";return fn;})(),
  "Position": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poste"])};fn.source="Poste";return fn;})(),
  "Invite Co-worker": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter un collègue"])};fn.source="Inviter un collègue";return fn;})(),
  "Candidate email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email du Candidat"])};fn.source="Email du Candidat";return fn;})(),
  "apply now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postulez"])};fn.source="Postulez";return fn;})(),
  "Company Information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l'entreprise"])};fn.source="Informations sur l'entreprise";return fn;})(),
  "Company Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])};fn.source="Nom de l'entreprise";return fn;})(),
  "select employees count": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectionner le nombre d'employés"])};fn.source="Selectionner le nombre d'employés";return fn;})(),
  "Company Website": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitwen de l'ntreprise"])};fn.source="Sitwen de l'ntreprise";return fn;})(),
  "select company's industry": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectionner le sécteur d'activité de l'entreprise"])};fn.source="Selectionner le sécteur d'activité de l'entreprise";return fn;})(),
  "Company Logo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de l'entreprise"])};fn.source="Logo de l'entreprise";return fn;})(),
  "Company Logo should be a PNG or JPG file of 250 x 250 pixels": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le logo de l'entreprise doit être un fichier PNG ou JPG de 250 x 250 pixels."])};fn.source="Le logo de l'entreprise doit être un fichier PNG ou JPG de 250 x 250 pixels.";return fn;})(),
  "Recruiter Information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le recruteur"])};fn.source="Informations sur le recruteur";return fn;})(),
  "Featured Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres d'emploi"])};fn.source="Offres d'emploi";return fn;})(),
  "Assess a": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer un"])};fn.source="Évaluer un";return fn;})(),
  "Form Steps": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séctions du formulaires"])};fn.source="Séctions du formulaires";return fn;})(),
  "First Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])};fn.source="Prénom";return fn;})(),
  "Last name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])};fn.source="Nom";return fn;})(),
  "Confirm password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])};fn.source="Confirmer le mot de passe";return fn;})(),
  "If you purchased a featured job, please insert your order ID below": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez acheté une offre d'emploi, veuillez indiquer votre numéro de commande ci-dessous."])};fn.source="Si vous avez acheté une offre d'emploi, veuillez indiquer votre numéro de commande ci-dessous.";return fn;})(),
  "Previous": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])};fn.source="Précédent";return fn;})(),
  "Next": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])};fn.source="Suivant";return fn;})(),
  "Submit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])};fn.source="Envoyer";return fn;})(),
  "Log out": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])};fn.source="Se déconnecter";return fn;})(),
  "Register Now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])};fn.source="S'inscrire";return fn;})(),
  "Find Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver un emploi"])};fn.source="Trouver un emploi";return fn;})(),
  "Find Candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver des candidats"])};fn.source="Trouver des candidats";return fn;})(),
  "registerFormDescription": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour soumettre un test d'évaluation pour approbation, veuillez remplir le formulaire suivant. Si vous avez des questions sur la procédure, veuillez contacter <a class=\"blue_span\"> hi&#64;go-platform.dz</a>. <br />Si vous souhaitez publier un offre emploi, veuillez acheter une <a class=\"blue_span\"> offre premium ici</a>. Ensuite, veuillez ajouter votre numéro de commande dans la section \"Offre d'emploi\"."])};fn.source="Pour soumettre un test d'évaluation pour approbation, veuillez remplir le formulaire suivant. Si vous avez des questions sur la procédure, veuillez contacter <a class=\"blue_span\"> hi&#64;go-platform.dz</a>. <br />Si vous souhaitez publier un offre emploi, veuillez acheter une <a class=\"blue_span\"> offre premium ici</a>. Ensuite, veuillez ajouter votre numéro de commande dans la section \"Offre d'emploi\".";return fn;})(),
  "Invoice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])};fn.source="Facture";return fn;})(),
  "Pricing Plans": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres"])};fn.source="Offres";return fn;})(),
  "Invoice ID": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])};fn.source="Référence";return fn;})(),
  "Download as PDF": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger PDF"])};fn.source="Télécharger PDF";return fn;})(),
  "Print": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer"])};fn.source="Imprimer";return fn;})(),
  "Recieve Payements": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recueillir les paiements"])};fn.source="Recueillir les paiements";return fn;})(),
  "Invoice to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour"])};fn.source="Pour";return fn;})(),
  "Invoice No": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture n&#176;"])};fn.source="Facture n&#176;";return fn;})(),
  "Order Num": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande n&#176;"])};fn.source="Commande n&#176;";return fn;})(),
  "Invoice Date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])};fn.source="Date";return fn;})(),
  "Payement Due": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement dû"])};fn.source="Paiement dû";return fn;})(),
  "Amount Due": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant dû"])};fn.source="Montant dû";return fn;})(),
  "Quantity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])};fn.source="Quantité";return fn;})(),
  "Rate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarif"])};fn.source="Tarif";return fn;})(),
  "Amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])};fn.source="Montant";return fn;})(),
  "Sub Total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous total"])};fn.source="Sous total";return fn;})(),
  "Tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe"])};fn.source="Taxe";return fn;})(),
  "My Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes offres"])};fn.source="Mes offres";return fn;})(),
  "Post Job": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poste de travail"])};fn.source="Poste de travail";return fn;})(),
  "No available jobs for now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun emploi disponible pour le moment"])};fn.source="Aucun emploi disponible pour le moment";return fn;})(),
  "Refferal": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommandation"])};fn.source="Recommandation";return fn;})(),
  "Completion": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achèvement"])};fn.source="Achèvement";return fn;})(),
  "Applicants": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidats"])};fn.source="Candidats";return fn;})(),
  "Assessements": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])};fn.source="Évaluations";return fn;})(),
  "Success Rate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de réussite"])};fn.source="Taux de réussite";return fn;})(),
  "On Track": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En bonne voie"])};fn.source="En bonne voie";return fn;})(),
  "Daily": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotidiennement"])};fn.source="Quotidiennement";return fn;})(),
  "Weekly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaire"])};fn.source="Hebdomadaire";return fn;})(),
  "Avg Completion Time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée moy d'achèvement"])};fn.source="Durée moy d'achèvement";return fn;})(),
  "Behind Schedule": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En retard"])};fn.source="En retard";return fn;})(),
  "Recent Applicants": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidats récents"])};fn.source="Candidats récents";return fn;})(),
  "View All": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher tout"])};fn.source="Afficher tout";return fn;})(),
  "send invitations": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer les invitaions"])};fn.source="Envoyer les invitaions";return fn;})(),
  "Close": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])};fn.source="Fermer";return fn;})(),
  "invite team members": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter des collegues"])};fn.source="Inviter des collegues";return fn;})(),
  "My Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes évaluations"])};fn.source="Mes évaluations";return fn;})(),
  "Invite a friend": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitez un ami"])};fn.source="Invitez un ami";return fn;})(),
  "you both get": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous obtenez tous les deux"])};fn.source="vous obtenez tous les deux";return fn;})(),
  "Invite your friends": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitez vos amis et commencez à travailler ensemble en quelques secondes. Toutes les personnes que vous invitez recevront un e-mail de bienvenue"])};fn.source="Invitez vos amis et commencez à travailler ensemble en quelques secondes. Toutes les personnes que vous invitez recevront un e-mail de bienvenue";return fn;})(),
  "Email Adress": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])};fn.source="Adresse email";return fn;})(),
  "Send Invitation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer une invitation"])};fn.source="Envoyer une invitation";return fn;})(),
  "More ways to invite your friends": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de possibilités d'inviter vos amis"])};fn.source="Plus de possibilités d'inviter vos amis";return fn;})(),
  "Invite with Gmail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter avec Gmail"])};fn.source="Inviter avec Gmail";return fn;})(),
  "Copied": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié"])};fn.source="Copié";return fn;})(),
  "Copy Link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien"])};fn.source="Copier le lien";return fn;})(),
  "Share on LinkedIn": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager sur LinkedIn"])};fn.source="Partager sur LinkedIn";return fn;})(),
  "RefferalViewBottomText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois que vous avez invité des amis, vous pouvez <a href=''> consulter l'état</a> de vos recommandations ou visiter notre <a href=''> Centre d'aide</a> si vous avez des questions."])};fn.source="Une fois que vous avez invité des amis, vous pouvez <a href=''> consulter l'état</a> de vos recommandations ou visiter notre <a href=''> Centre d'aide</a> si vous avez des questions.";return fn;})(),
  "Candidate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidat"])};fn.source="Candidat";return fn;})(),
  "Set Success Score": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["score de réussite"])};fn.source="score de réussite";return fn;})(),
  "Selected Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations sélectionnées"])};fn.source="Évaluations sélectionnées";return fn;})(),
  "Select": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séléctionner"])};fn.source="Séléctionner";return fn;})(),
  "Destribution by response time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destribution par temps de réponse"])};fn.source="Destribution par temps de réponse";return fn;})(),
  "This chart represents the time of candidates to pass the test after it was sent to them": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce graphe représente le temps nécessaire aux candidats pour réussir le test après qu'il leur a été envoyé."])};fn.source="Ce graphe représente le temps nécessaire aux candidats pour réussir le test après qu'il leur a été envoyé.";return fn;})(),
  "Edit project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le projet"])};fn.source="Modifier le projet";return fn;})(),
  "Project name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de projet"])};fn.source="Nom de projet";return fn;})(),
  "Job Title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de poste"])};fn.source="Titre de poste";return fn;})(),
  "select job seniority": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séléctionner le niveau de poste"])};fn.source="Séléctionner le niveau de poste";return fn;})(),
  "Edit assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les évaluations"])};fn.source="Modifier les évaluations";return fn;})(),
  "save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])};fn.source="Sauvegarder";return fn;})(),
  "Applicant dropout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abondon du candidats"])};fn.source="Abondon du candidats";return fn;})(),
  "spent this month": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépensés ce mois-ci"])};fn.source="Dépensés ce mois-ci";return fn;})(),
  "Applicant revival": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouvellement du candidat"])};fn.source="Renouvellement du candidat";return fn;})(),
  "AVG spent per hire": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût moy par embauche"])};fn.source="Coût moy par embauche";return fn;})(),
  "Recent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récent"])};fn.source="Récent";return fn;})(),
  "Latest Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières offres d'emploi"])};fn.source="Dernières offres d'emploi";return fn;})(),
  "All Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les offres d'emploi"])};fn.source="Toutes les offres d'emploi";return fn;})(),
  "Categories": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])};fn.source="Catégories";return fn;})(),
  "Development": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement"])};fn.source="Développement";return fn;})(),
  "Design": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])};fn.source="Design";return fn;})(),
  "Marketing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])};fn.source="Marketing";return fn;})(),
  "Business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affaires"])};fn.source="Affaires";return fn;})(),
  "Support": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])};fn.source="Support";return fn;})(),
  "Email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])};fn.source="Email";return fn;})(),
  "Password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])};fn.source="Mot de passe";return fn;})(),
  "Forgot Password?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])};fn.source="Mot de passe oublié ?";return fn;})(),
  "Already have an account? ": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte ?"])};fn.source="Vous avez déjà un compte ?";return fn;})(),
  "sign up": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])};fn.source="S'inscrire";return fn;})(),
  "Fill in your Email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez votre e-mail"])};fn.source="Remplissez votre e-mail";return fn;})(),
  "Don't have access to these informations?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas accès à ces informations ?"])};fn.source="Vous n'avez pas accès à ces informations ?";return fn;})(),
  "Read more!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus!"])};fn.source="En savoir plus!";return fn;})(),
  "Discover More Insightful Articles": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez plus d'articles inspirants"])};fn.source="Découvrez plus d'articles inspirants";return fn;})(),
  "The GO PLATFORM community": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La communauté GO PLATFORM"])};fn.source="La communauté GO PLATFORM";return fn;})(),
  "Join us": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez nous"])};fn.source="Rejoignez nous";return fn;})(),
  "Talent email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer votre email"])};fn.source="Entrer votre email";return fn;})(),
  "talent invitation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["envoyer"])};fn.source="envoyer";return fn;})(),
  "Your Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre Nom"])};fn.source="Votre Nom";return fn;})(),
  "Your Email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre Email"])};fn.source="Votre Email";return fn;})(),
  "Filter by project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectionner un projet"])};fn.source="Selectionner un projet";return fn;})(),
  "Filter by status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statut des candidats"])};fn.source="statut des candidats";return fn;})(),
  "passed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passé"])};fn.source="passé";return fn;})(),
  "invited": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invité"])};fn.source="invité";return fn;})(),
  "Payements": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])};fn.source="Paiements";return fn;})(),
  "manage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gérer"])};fn.source="gérer";return fn;})(),
  "Credit cards": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes de crédit"])};fn.source="Cartes de crédit";return fn;})(),
  "Nme/Business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom/Entreprise"])};fn.source="Nom/Entreprise";return fn;})(),
  "Type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taper"])};fn.source="Taper";return fn;})(),
  "Number": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])};fn.source="Nombre";return fn;})(),
  "Expire": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expirer"])};fn.source="Expirer";return fn;})(),
  "Send Message": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le message"])};fn.source="Envoyer le message";return fn;})(),
  "employees": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["employées"])};fn.source="employées";return fn;})(),
  "Overview": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])};fn.source="Aperçu";return fn;})(),
  "Benefits": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avantages"])};fn.source="Avantages";return fn;})(),
  "Add a new card": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle carte"])};fn.source="Ajouter une nouvelle carte";return fn;})(),
  "Team": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe"])};fn.source="Équipe";return fn;})(),
  "Role Category": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de rôle"])};fn.source="Catégorie de rôle";return fn;})(),
  "Compensation & Benefit Consultant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant en rémunération et avantages sociaux"])};fn.source="Consultant en rémunération et avantages sociaux";return fn;})(),
  "Experience Requirement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exigence d'expérience"])};fn.source="Exigence d'expérience";return fn;})(),
  "Finance": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])};fn.source="Finance";return fn;})(),
  "Working Location": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de travail"])};fn.source="Lieu de travail";return fn;})(),
  "Jakerta": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djakarta"])};fn.source="Djakarta";return fn;})(),
  "Easy Apply": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application facile"])};fn.source="Application facile";return fn;})(),
  "Our company": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre compagnie"])};fn.source="Notre compagnie";return fn;})(),
  "Add photos": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des photos"])};fn.source="Ajouter des photos";return fn;})(),
  "More": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus"])};fn.source="Plus";return fn;})(),
  "Invited recruiter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruteur invité"])};fn.source="Recruteur invité";return fn;})(),
  "Select a project, and assess your talents!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez d'inviter des talents à passer une évaluation ou demandez de l'aide sur notre"])};fn.source="Essayez d'inviter des talents à passer une évaluation ou demandez de l'aide sur notre";return fn;})(),
  "Slack Channel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canal Slack."])};fn.source="canal Slack.";return fn;})(),
  "Top Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleures évaluations"])};fn.source="Meilleures évaluations";return fn;})(),
  "Search Assessment, Job Position": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une évaluation, un poste"])};fn.source="Rechercher une évaluation, un poste";return fn;})(),
  "accuracyText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos évaluations précises vont au-delà de l'évaluation des connaissances pour mesurer les compétences réelles et l'aptitude au travail."])};fn.source="Nos évaluations précises vont au-delà de l'évaluation des connaissances pour mesurer les compétences réelles et l'aptitude au travail.";return fn;})(),
  "timeEffectivenessText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recrutez plus rapidement grâce à nos évaluations de compétences personnalisables. Identifiez les compétences spécifiques pour chaque rôle, rationalisez votre processus de recrutement et faites une sélection des candidats basée sur les données."])};fn.source="Recrutez plus rapidement grâce à nos évaluations de compétences personnalisables. Identifiez les compétences spécifiques pour chaque rôle, rationalisez votre processus de recrutement et faites une sélection des candidats basée sur les données.";return fn;})(),
  "Cost Efficiency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentabilité"])};fn.source="Rentabilité";return fn;})(),
  "costEficiencyText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourvoyez vos postes plus rapidement et économisez de l'argent. Nos évaluations de compétences validées identifient rapidement les talents correspondant le mieux aux postes afin d'éviter les coûteuses vacances de postes et les mauvais recrutements."])};fn.source="Pourvoyez vos postes plus rapidement et économisez de l'argent. Nos évaluations de compétences validées identifient rapidement les talents correspondant le mieux aux postes afin d'éviter les coûteuses vacances de postes et les mauvais recrutements.";return fn;})(),
  "contactText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chez GO Platform, nous savons que les besoins de chaque organisation sont uniques. C'est pourquoi nous adorons construire des solutions d’évaluation sur mesure des compétences adaptées aux priorités et aux défis de chaque partenaire en matière de talents."])};fn.source="Chez GO Platform, nous savons que les besoins de chaque organisation sont uniques. C'est pourquoi nous adorons construire des solutions d’évaluation sur mesure des compétences adaptées aux priorités et aux défis de chaque partenaire en matière de talents.";return fn;})(),
  "Select a title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un titre"])};fn.source="Sélectionnez un titre";return fn;})(),
  "NO DATA": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de Données"])};fn.source="Pas de Données";return fn;})(),
  "Hire Squad": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiter"])};fn.source="Recruiter";return fn;})(),
  "Search Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher une Évaluation."])};fn.source="Chercher une Évaluation.";return fn;})(),
  "Insights": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])};fn.source="Aperçu";return fn;})(),
  "Struggling talents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talents en difficulté"])};fn.source="Talents en difficulté";return fn;})(),
  "Skilled talents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talents qualifiés"])};fn.source="Talents qualifiés";return fn;})(),
  "lack of proficiency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manque de compétence"])};fn.source="Manque de compétence";return fn;})(),
  "HIgh level of proficiency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haut niveau de compétence"])};fn.source="Haut niveau de compétence";return fn;})(),
  "Number of questions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions par test"])};fn.source="Questions par test";return fn;})(),
  "Register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])};fn.source="S'inscrire";return fn;})(),
  "Let's get to know you better": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprenons à mieux vous connaître..."])};fn.source="Apprenons à mieux vous connaître...";return fn;})(),
  "Invite candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter des candidats"])};fn.source="Inviter des candidats";return fn;})(),
  "Clear List": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vider la liste"])};fn.source="Vider la liste";return fn;})(),
  "This preview mode is not interactive, however, the assessment your candidate will see is": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mode d'aperçu n'est pas interactif, cependant, l'évaluation que votre candidat verra est"])};fn.source="Ce mode d'aperçu n'est pas interactif, cependant, l'évaluation que votre candidat verra est";return fn;})(),
  "project name tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez un nom qui décrit le mieux cette évaluation."])};fn.source="Entrez un nom qui décrit le mieux cette évaluation.";return fn;})(),
  "category tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la catégorie du poste pour lequel vous recrutez, afin que nous puissions filtrer les emplois pour vous."])};fn.source="Sélectionnez la catégorie du poste pour lequel vous recrutez, afin que nous puissions filtrer les emplois pour vous.";return fn;})(),
  "job position tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le rôle qui correspond le mieux à celui pour lequel vous recrutez, afin que nous puissions vous suggérer les tests appropriés."])};fn.source="Choisissez le rôle qui correspond le mieux à celui pour lequel vous recrutez, afin que nous puissions vous suggérer les tests appropriés.";return fn;})(),
  "job seniority tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le niveau de séniorité du poste pour lequel vous recrutez."])};fn.source="Sélectionnez le niveau de séniorité du poste pour lequel vous recrutez.";return fn;})(),
  "Upgrade": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade"])};fn.source="Upgrade";return fn;})(),
  "created by tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez utiliser un modèle d'évaluation créé par nos experts qui comprend la bonne sélection de tests et de questions personnalisées pour obtenir les meilleures embauches, ou créer votre propre évaluation."])};fn.source="Vous pouvez utiliser un modèle d'évaluation créé par nos experts qui comprend la bonne sélection de tests et de questions personnalisées pour obtenir les meilleures embauches, ou créer votre propre évaluation.";return fn;})()
}